import React, { useState, useEffect } from "react";
import {
  Dialog,
  PlaceMap,
  PlacePrice,
  PlaceRating,
  TopNav,
} from "../../components";
import { useParams } from "react-router-dom";
import styles from "./PlaceDetailScreen.module.css";
import { imageURL } from "../../api";
import axios from "../../axios";
import { requests } from "../../api";

function Header({ place }) {
  const { name, thumb } = place || {};

  return (
    <div
      className={styles.header}
      style={{
        backgroundImage: `url(${imageURL}${thumb})`,
      }}
    >
      <div>
        <h1>{name}</h1>
        <div>
          <PlaceRating place={place} showReviews={true} />
          <span className={styles.dot} />
          <PlacePrice place={place} />
        </div>
      </div>
    </div>
  );
}

function AmenitiesTab({ placeDetail }) {
  const { amenities } = placeDetail;

  return (
    <div className={styles.amenitiesTab}>
      {
        <div className={styles.amenitiesList}>
          {amenities.map((amenity, idx) => (
            <div key={idx}>
              <img src={`${imageURL}${amenity.icon}`} alt={amenity.name} />
              <p>{amenity.name}</p>
            </div>
          ))}
        </div>
      }
    </div>
  );
}

function SectionHeading({ title }) {
  return <h3 className={styles.sectionHeading}>{title}</h3>;
}

function Overview({ placeDetail }) {
  const [showOverviewDialog, setShowOverviewDialog] = useState(false);
  const handleShowMoreClick = (e) => {
    e.preventDefault();
    setShowOverviewDialog(true);
  };
  const handleBack = (e) => {
    setShowOverviewDialog(false);
  };

  return (
    <>
      <div className={styles.overview}>
        <SectionHeading title="Overview" />
        <p
          dangerouslySetInnerHTML={{ __html: placeDetail?.place?.description }}
        />
        <a href="/#" onClick={handleShowMoreClick}>
          Show more
        </a>
      </div>
      <Dialog
        title="Overview"
        active={showOverviewDialog}
        onBackClick={handleBack}
      >
        <div
          className={styles.overviewText}
          dangerouslySetInnerHTML={{
            __html: placeDetail?.place?.description,
          }}
        />
      </Dialog>
    </>
  );
}

function MapView({ placeDetail }) {
  const [showMapDialog, setShowMapDialog] = useState(false);
  const { place } = placeDetail;
  const handleMapClick = () => setShowMapDialog(true);
  const handleBack = () => setShowMapDialog(false);

  return (
    <>
      <div className={styles.mapView}>
        <SectionHeading title="Map View" />
        <div onClick={handleMapClick}>
          <PlaceMap place={place} />
        </div>
      </div>
      <Dialog active={showMapDialog} hideNav={true}>
        <div className={styles.mapDialog}>
          <div className={styles.backButton} onClick={handleBack}>
            <i className="las la-angle-left" />
            <span>Back</span>
          </div>
          <PlaceMap place={place} gestureHandling="greedy" />
        </div>
      </Dialog>
    </>
  );
}

function LocationContact({ placeDetail }) {
  const { place } = placeDetail;
  const { address, email, phone_number } = place || {};

  return (
    <div className={styles.locationContact}>
      <SectionHeading title="Location & Contact" />
      <div>
        {address && (
          <>
            <i className="las la-map-marked-alt" />
            <p>{address}</p>
          </>
        )}
        {email && (
          <>
            <i className="las la-envelope"></i>
            <p>{email}</p>
          </>
        )}
        {phone_number && (
          <>
            <i className="las la-phone"></i>
            <p>{phone_number}</p>
          </>
        )}
      </div>
    </div>
  );
}

function OpeningTime({ placeDetail }) {
  const { place } = placeDetail;
  const { opening_hour } = place || {};

  const showLessLength = 2;
  const showMoreVisible = opening_hour && opening_hour.length > showLessLength;
  const [showFull, setShowFull] = useState(true);

  const buildTimeGrid = () => {
    const showingLength = showFull ? opening_hour.length : showLessLength;
    const rows = [];
    for (let i = 0; i < showingLength; i++) {
      rows.push(
        <span key={`title-${i}`} className={styles.title}>
          {opening_hour[i].title}
        </span>
      );
      rows.push(
        <span key={`value-${i}`} className={styles.value}>
          {opening_hour[i].value}
        </span>
      );
    }
    return <div className={styles.timeGrid}>{rows}</div>;
  };

  const handleShowMoreClick = (e) => {
    e.preventDefault();
    setShowFull(!showFull);
  };

  return (
    <div className={styles.openingTime}>
      <SectionHeading title="Opening Time" />
      <div className={styles.openingTimeList}>{buildTimeGrid()}</div>
      {showMoreVisible && (
        <a href="/#" onClick={handleShowMoreClick}>
          {showFull ? "Show less" : "Show more"}
        </a>
      )}
    </div>
  );
}

function Avatar({ user }) {
  return user.avatar ? (
    <img
      className={styles.avatar}
      src={`${imageURL}${user.avatar}`}
      alt={user.name}
    />
  ) : (
    <div className={styles.avatarPlaceholder}>
      <i className="las la-image" />
    </div>
  );
}

function RatingStars({ score }) {
  return (
    <div className={styles.ratingStars}>
      {[...Array(score)].map((n, idx) => (
        <i key={idx} className="las la-star" />
      ))}
    </div>
  );
}

function Review({ placeDetail }) {
  const { review_score_avg, reviews } = placeDetail;

  const buildReviewList = () => {
    return reviews.map(({ id, user, score, created_at, comment }) => (
      <div key={id} className={styles.reviewItem}>
        <div className={styles.reviewHeader}>
          <Avatar user={user} />
          <div className={styles.reviewInfo}>
            <div>
              <p>{user.name}</p>
              <RatingStars score={score} />
            </div>
            <span>{created_at}</span>
          </div>
        </div>
        <div className={styles.reviewComment}>{comment}</div>
      </div>
    ));
  };

  return (
    <div className={styles.review}>
      <h3>
        Tips{" "}
        {review_score_avg && (
          <span>
            {review_score_avg} <i className="las la-star" />
          </span>
        )}
      </h3>
      {buildReviewList()}
    </div>
  );
}

function PlaceDetailScreen(props) {
  const { id } = useParams();
  const place = props.location.state?.place || null;
  const [placeDetail, setPlaceDetail] = useState(null);

  useEffect(() => {
    async function fetchPlaceDetail() {
      const result = await axios(requests.fetchPlaceDetail.replace("{id}", id));
      setPlaceDetail(result.data.data);
    }
    fetchPlaceDetail();
  }, [id]);

  return (
    <div>
      <TopNav title={place?.name || placeDetail?.place?.name || ""} />
      <Header place={place || placeDetail?.place} />
      {placeDetail && (
        <>
          <AmenitiesTab placeDetail={placeDetail} />
          <Overview placeDetail={placeDetail} />
          <MapView placeDetail={placeDetail} />
          <LocationContact placeDetail={placeDetail} />
          <OpeningTime placeDetail={placeDetail} />
          <Review placeDetail={placeDetail} />
        </>
      )}
    </div>
  );
}

export default PlaceDetailScreen;
