import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./Search.module.css";

function Search({ cancelVisible, autoFocus, onChange }) {
  const inputRef = useRef();

  useEffect(() => {
    if (autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  return (
    <div className={styles.root}>
      <div>
        <i className="las la-search" />
        <input
          ref={inputRef}
          placeholder="Enter a city or location"
          onChange={onChange}
        />
      </div>
      {cancelVisible && <Link to="/">Cancel</Link>}
    </div>
  );
}

Search.defaultProps = {
  cancelVisible: false,
  autoFocus: false,
};

export default Search;
