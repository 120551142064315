import React from "react";
import styles from "./PlaceCard.module.css";
import { Link } from "react-router-dom";
import { imageURL } from "../../api";
import { PlacePrice, PlaceRating } from "../index";

function PlaceCard({ place }) {
  const getPlaceTypes = (place) =>
    place.place_types?.map((placeType) => placeType.name)?.join("\n");

  return (
    <div className={styles.root}>
      <Link
        to={{ pathname: `/places/${place.id}`, state: { place } }}
        className={styles.cardLink}
      >
        <div
          className={styles.background}
          style={{
            backgroundImage: `url(${imageURL}${place.thumb})`,
          }}
        >
          <div className={styles.footer}>
            <p>{getPlaceTypes(place)}</p>
            <h3>{place.name}</h3>
            <div>
              <PlaceRating place={place} />
              <PlacePrice place={place} />
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default PlaceCard;
