import React, { useState, useEffect } from "react";
import styles from "./CitiesScreen.module.css";
import axios from "../../axios";
import { requests } from "../../api";
import { CityCard } from "../../components";

function CitiesScreen() {
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchCities() {
      const result = await axios(requests.fetchCities);
      setCities(result.data.data);
      setLoading(false);
    }
    fetchCities();
  }, []);

  const builtCityList = () => {
    if (loading) {
      return [...Array(4)].map((n, idx) => (
        <CityCard key={idx} loading={true} />
      ));
    } else {
      return cities.map((city) => <CityCard key={city.id} city={city} />);
    }
  };

  return (
    <div className={styles.root}>
      <h2>All Cities</h2>
      <div className={styles.grid}>{builtCityList()}</div>
    </div>
  );
}

export default CitiesScreen;
