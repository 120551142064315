import React from "react";
import styles from "./Splash.module.css";
import logo from "../../assets/images/logo.png";

function Splash() {
  return (
    <div className={styles.root}>
      <div>
        <img src={logo} alt="Loca Insight" />
        <h1>Loca Insight</h1>
        <h5>Get around like a local</h5>
        <div className={styles.spinner}>
          <div className={styles.dot1}></div>
          <div className={styles.dot2}></div>
        </div>
      </div>
      <div className={styles.footer}>
        <p>V.1.0.0</p>
        <p>2021 © Loca Company Limited</p>
      </div>
    </div>
  );
}

export default Splash;
