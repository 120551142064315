import React, { useEffect } from "react";
import styles from "./Dialog.module.css";
import { TopNav } from "../index";
import cx from "classnames";

function Dialog({
  title,
  active,
  children,
  bgTransparent,
  onBackClick,
  hideNav,
  onClearClick,
}) {
  useEffect(() => {
    if (active) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [active]);

  return (
    <div
      className={cx({
        [styles.root]: true,
        [styles.active]: active,
      })}
    >
      <div
        className={cx({
          [styles.nav]: true,
          [styles.transparent]: !!bgTransparent,
        })}
      >
        {!hideNav && (
          <TopNav
            title={title}
            disableTransition={true}
            onBackClick={onBackClick}
            onClearClick={onClearClick}
          />
        )}
        {children}
      </div>
    </div>
  );
}

Dialog.defaultProps = {
  hideNav: false,
};

export default Dialog;
