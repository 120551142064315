import React from "react";
import styles from "./PlaceRating.module.css";
import Pluralize from "pluralize";

function PlaceRating({ place, showReviews }) {
  const { avg_review, reviews_count } = place || {};

  return (
    <span className={styles.root}>
      {avg_review?.[0]?.aggregate}
      {reviews_count ? (
        <>
          <i className="las la-star" />
          <span>
            ({reviews_count}
            {showReviews && Pluralize(" review", reviews_count)})
          </span>
        </>
      ) : (
        <>(No review)</>
      )}
    </span>
  );
}

export default PlaceRating;
