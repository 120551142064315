import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  CitiesScreen,
  CityDetailScreen,
  HomeScreen,
  PlaceDetailScreen,
  SearchScreen,
} from "./screens";
import { BottomNav, withSplashScreen } from "./components";
import "./App.css";

function App() {
  const RootContainer = () => (
    <div className="root-container">
      <Route exact path="/" component={HomeScreen} />
      <Route exact path="/cities" component={CitiesScreen} />
      <BottomNav />
    </div>
  );

  const DetailContainer = () => (
    <>
      <Route path="/cities/:id" component={CityDetailScreen} />
      <Route path="/places/:id" component={PlaceDetailScreen} />
      <Route path="/search" component={SearchScreen} />
    </>
  );

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path={["/", "/cities"]} component={RootContainer} />
          <Route component={DetailContainer} />
        </Switch>
      </Router>
    </div>
  );
}

export default withSplashScreen(App);
