import React from "react";
import styles from "./MapMarker.module.css";
function MapMarker({ categoryId, alt, onClick }) {
  let src = null;
  switch (categoryId) {
    case 11:
      src = "/images/icon-see@3x.png";
      break;
    case 12:
      src = "/images/icon-eat-drink@3x.png";
      break;
    case 13:
      src = "/images/icon-stay@3x.png";
      break;
    case 21:
      src = "/images/icon-shop@3x.png";
      break;
    default:
      src = "/images/icon-shop@3x.png";
      break;
  }
  return (
    <div className={styles.root} onClick={onClick}>
      <img src={src} alt={alt} />
    </div>
  );
}

export default MapMarker;
