import React from "react";
import styles from "./BottomNav.module.css";
import { NavLink } from "react-router-dom";

function BottomNav() {
  return (
    <div className={styles.root}>
      <div className={styles.item}>
        <NavLink exact to="/" activeClassName={styles.active}>
          <i className="las la-home" />
        </NavLink>
      </div>
      <div className={styles.item}>
        <NavLink exact to="/cities" activeClassName={styles.active}>
          <i className="las la-city" />
        </NavLink>
      </div>
    </div>
  );
}

export default BottomNav;
