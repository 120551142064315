import React from "react";
import styles from "./CityCard.module.css";
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from "react-router-dom";
import { imageURL } from "../../api";

function CityCard({ city, loading }) {
  return (
    <div className={styles.root}>
      {loading ? (
        <div className={styles.loader}>
          <ClipLoader color={"#00c5fd"} size={30} />
        </div>
      ) : (
        <Link
          to={{ pathname: `/cities/${city.id}`, state: { city } }}
          className={styles.cardLink}
        >
          <div
            className={styles.background}
            style={{
              backgroundImage: `url(${imageURL}${city.thumb})`,
            }}
          >
            {city.country && (
              <div className={styles.country}>{city.country.name}</div>
            )}
            <div className={styles.footer}>
              <h2>{city.name}</h2>
              <p>{city.places_count || 0} places</p>
            </div>
          </div>
        </Link>
      )}
    </div>
  );
}

export default CityCard;
