import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./HomeScreen.module.css";
import axios from "../../axios";
import { requests, imageURL } from "../../api";
import { Dialog, Search, Heading, CityCard, StoryCard } from "../../components";

function HomeSearch() {
  return (
    <div className={styles.homeSearch}>
      <h1>Explore the world</h1>
      <Link to="/search">
        <Search />
      </Link>
    </div>
  );
}

function HomeCities() {
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchCities() {
      const result = await axios(requests.fetchCities);
      setCities(result.data.data);
      setLoading(false);
    }
    fetchCities();
  }, []);

  const builtCityList = () => {
    if (loading) {
      return [...Array(4)].map((n, idx) => (
        <CityCard key={idx} loading={true} />
      ));
    } else {
      return cities.map((city) => <CityCard key={city.id} city={city} />);
    }
  };

  return (
    <div>
      <Heading title="Popular cities" to="/cities" />
      <div className="scroll-list">{builtCityList()}</div>
    </div>
  );
}

function HomeStories() {
  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showStoryDialog, setShowStoryDialog] = useState(false);
  const [selectedStory, setSelectedStory] = useState(null);

  useEffect(() => {
    async function fetchStories() {
      const result = await axios(requests.fetchStories);
      setStories(result.data.data);
      setLoading(false);
    }
    fetchStories();
  }, []);

  const openStory = (story) => {
    setSelectedStory(story);
    setShowStoryDialog(true);
  };

  const handleDialogBackClick = () => {
    setShowStoryDialog(false);
    setSelectedStory(null);
  };

  const buildStoryList = () => {
    if (loading) {
      return [...Array(4)].map((n, idx) => (
        <StoryCard key={idx} loading={true} />
      ));
    } else {
      return stories.map((story) => (
        <StoryCard
          key={story.id}
          story={story}
          onClick={() => openStory(story)}
        />
      ));
    }
  };

  return (
    <div>
      <Heading title="Related stories" />
      <div className="scroll-list">{buildStoryList()}</div>
      <Dialog
        active={showStoryDialog}
        bgTransparent={true}
        onBackClick={handleDialogBackClick}
      >
        {selectedStory && (
          <div className={styles.storyDialogContent}>
            <div
              className={styles.background}
              style={{
                backgroundImage: `url(${imageURL}${selectedStory.thumb})`,
              }}
            >
              <div className={styles.storyHeading}>
                {selectedStory.categories.length && (
                  <p>{selectedStory.categories[0].name}</p>
                )}
                <h1>{selectedStory.title}</h1>
              </div>
            </div>
            <div className={styles.storyContent}>
              <div
                dangerouslySetInnerHTML={{ __html: selectedStory.content }}
              />
            </div>
          </div>
        )}
      </Dialog>
    </div>
  );
}

function HomeScreen() {
  return (
    <div className={styles.root}>
      <HomeSearch />
      <HomeCities />
      <HomeStories />
    </div>
  );
}

export default HomeScreen;
