import React from "react";
import { Map, MapMarker } from "../index";

function CityMap({
  city,
  features,
  selectedCategoryId,
  gestureHandling,
  onMarkerClick,
}) {
  const defaultCenter = { lat: city.lat, lng: city.lng };
  const getMarkers = () => {
    let markers = [];

    if (features?.length) {
      for (const feature of features) {
        if (selectedCategoryId && feature.category_id !== selectedCategoryId) {
          continue;
        }
        if (feature.places?.length) {
          markers.push(
            ...feature.places.map((place) => (
              <MapMarker
                key={`${feature.category_id}-${place.id}`}
                lat={place.lat}
                lng={place.lng}
                alt={place.name}
                categoryId={feature.category_id}
                onClick={() => onMarkerClick && onMarkerClick(place)}
              />
            ))
          );
        }
      }
    }
    return markers;
  };

  return (
    <Map defaultCenter={defaultCenter} gestureHandling={gestureHandling}>
      {getMarkers()}
    </Map>
  );
}

CityMap.defaultProps = {
  gestureHandling: "auto",
};

export default CityMap;
