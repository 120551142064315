import React, { useState, useEffect } from "react";
import styles from "./TopNav.module.css";
import { useHistory } from "react-router-dom";

function TopNav({ title, disableTransition, onBackClick, onClearClick }) {
  const [bgOpacity, setBgOpacity] = useState(disableTransition ? 1 : 0);
  const [titleOpacity, setTitleOpacity] = useState(disableTransition ? 1 : 0);
  const history = useHistory();

  const transitionNav = () => {
    const opacity = Math.min(255, window.scrollY) / 255;
    setBgOpacity(opacity);
    setTitleOpacity(window.scrollY > 255 ? 1 : 0);
  };

  useEffect(() => {
    if (!disableTransition) {
      window.addEventListener("scroll", transitionNav);
    }
    return () => {
      if (disableTransition) {
        window.removeEventListener("scroll", transitionNav);
      }
    };
  }, [disableTransition]);

  return (
    <div
      className={styles.root}
      style={{ backgroundColor: `rgba(0,197,253,${bgOpacity})` }}
    >
      <i
        className="las la-angle-left"
        onClick={() => (onBackClick ? onBackClick() : history.goBack())}
      />
      {title && <h2 style={{ opacity: titleOpacity }}>{title}</h2>}
      {onClearClick && <span onClick={onClearClick}>Clear</span>}
    </div>
  );
}

TopNav.defaultProps = {
  disableTransition: false,
};

export default TopNav;
