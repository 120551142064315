import React, { useState } from "react";
import styles from "./GroupOption.module.css";
import cx from "classnames";

function GroupOption({ title, options, onChange }) {
  const [expanded, setExpanded] = useState(true);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleChange = (e) => {
    const key = e.target.value;
    const selected = e.target.checked;
    onChange(key, selected);
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h3>{title}</h3>
        <i
          className={cx({
            las: true,
            "la-angle-up": expanded,
            "la-angle-down": !expanded,
          })}
          onClick={handleExpandClick}
        />
      </div>
      {expanded && (
        <div className={styles.options}>
          {options.map((option) => (
            <div key={option.key} className={styles.optionWrapper}>
              <input
                id={option.key}
                name={option.key}
                type="checkbox"
                value={option.key}
                onChange={handleChange}
                checked={option.selected}
              />
              <label htmlFor={option.key}>{option.title}</label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default GroupOption;
