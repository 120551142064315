import React, { useState, useEffect, useMemo } from "react";
import {
  CityMap,
  Dialog,
  GroupOption,
  Heading,
  PlaceCard,
  PlacePrice,
  PlaceRating,
  TopNav,
} from "../../components";
import { Link, useParams } from "react-router-dom";
import cx from "classnames";
import styles from "./CityDetailScreen.module.css";
import { imageURL } from "../../api";
import axios from "../../axios";
import { requests } from "../../api";

function Header({ city }) {
  const { name, intro, thumb } = city || {};

  return (
    <div
      className={styles.header}
      style={{
        backgroundImage: `url(${imageURL}${thumb})`,
      }}
    >
      <div>
        <h1>{name}</h1>
        <p>{intro}</p>
      </div>
    </div>
  );
}

function FeaturesTab({
  features,
  loading,
  selectedCategoryId,
  onCategoryChange,
}) {
  const homeFeature = { category_id: null, category_name: "Home" };
  const completeFeatures = features ? [homeFeature, ...features] : null;

  return (
    <div className={styles.featuresTab}>
      {!loading && completeFeatures && (
        <div className={styles.featuresList}>
          {completeFeatures.map((feature, idx) => (
            <div
              key={idx}
              onClick={() => onCategoryChange(feature.category_id)}
            >
              <span
                className={cx({
                  [styles.active]: selectedCategoryId === feature.category_id,
                })}
              >
                {feature?.category_name?.toUpperCase()}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function CityMapSection({ city, cityDetail, selectedCategoryId }) {
  const [showMapDialog, setShowMapDialog] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const handleMapClick = () => setShowMapDialog(true);
  const handleBack = () => setShowMapDialog(false);

  const handleMarkerClick = (place) => {
    const placeCategoryIds = place.category?.map((c) => parseInt(c)) || [];
    const categories =
      placeCategoryIds?.length && cityDetail?.features.length
        ? cityDetail?.features?.filter((feature) =>
            placeCategoryIds.includes(feature.category_id)
          )
        : [];
    setSelectedPlace({
      ...place,
      categories,
    });
  };

  return (
    <>
      <div onClick={handleMapClick}>
        <CityMap
          city={city}
          features={cityDetail?.features}
          selectedCategoryId={selectedCategoryId}
        />
      </div>
      <Dialog active={showMapDialog} hideNav={true}>
        <div className={styles.mapDialog}>
          <div className={styles.backButton} onClick={handleBack}>
            <i className="las la-angle-left" />
            <span>Back to list</span>
          </div>
          <CityMap
            city={city}
            features={cityDetail?.features}
            selectedCategoryId={selectedCategoryId}
            gestureHandling="greedy"
            onMarkerClick={handleMarkerClick}
          />
          {selectedPlace && (
            <div className={styles.placeModal}>
              <div
                className={styles.placeModalBackground}
                style={{
                  backgroundImage: `url(${imageURL}${selectedPlace.thumb})`,
                }}
              />
              <div className={styles.placeModalContent}>
                <div className={styles.placeInfo}>
                  {selectedPlace.categories?.length && (
                    <div className={styles.placeInfoCategory}>
                      {selectedPlace.categories.map((category, idx) => (
                        <span key={idx}>{category.category_name}</span>
                      ))}
                    </div>
                  )}
                  <Link
                    to={{
                      pathname: `/places/${selectedPlace.id}`,
                      state: { selectedPlace },
                    }}
                  >
                    <h3>{selectedPlace.name}</h3>
                    <div className={styles.placeReview}>
                      <PlaceRating place={selectedPlace} showReviews={true} />
                      <PlacePrice place={selectedPlace} />
                    </div>
                  </Link>
                </div>
                <div
                  className={styles.cancelButton}
                  onClick={() => setSelectedPlace(null)}
                >
                  <i className="las la-times" />
                </div>
              </div>
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
}

function MultiCategories({ cityDetail, selectedCategoryId, onCategoryChange }) {
  const { features, place_types, amenities } = cityDetail;
  const initialFilters = useMemo(
    () => ({
      prices: [],
      types: [],
      amenities: [],
    }),
    []
  );

  const [showFilter, setShowFilter] = useState(false);
  const [activeFilters, setActiveFilters] = useState({ ...initialFilters });
  const [selectionFilters, setSelectionFilters] = useState({
    ...initialFilters,
  });
  const isFilterActive = Object.keys(activeFilters).some(
    (filterType) => activeFilters[filterType].length
  );
  const priceOptions = [
    { title: "Free", key: 0 },
    { title: "Low: $", key: 1 },
    { title: "Medium: $$", key: 2 },
    { title: "High: $$$", key: 3 },
  ].map((opt) => ({
    ...opt,
    selected: selectionFilters["prices"].includes(opt.key.toString()),
  }));
  const typesOptions = place_types
    ?.map((placeType) => ({
      title: placeType.name,
      key: placeType.id,
    }))
    .map((opt) => ({
      ...opt,
      selected: selectionFilters["types"].includes(opt.key.toString()),
    }))
    .sort((a, b) => (a.title > b.title ? 1 : -1));
  const amenitiesOptions = amenities
    ?.map((amenity) => ({
      title: amenity.name,
      key: amenity.id,
    }))
    .map((opt) => ({
      ...opt,
      selected: selectionFilters["amenities"].includes(opt.key.toString()),
    }))
    .sort((a, b) => (a.title > b.title ? 1 : -1));

  useEffect(() => {
    if (!selectedCategoryId && isFilterActive) {
      setActiveFilters({ ...initialFilters });
      setSelectionFilters({ ...initialFilters });
    }
  }, [selectedCategoryId, isFilterActive, initialFilters]);

  useEffect(() => {
    if (showFilter) {
      setSelectionFilters({ ...activeFilters });
    }
  }, [showFilter, activeFilters]);

  const handleViewAllClick = (e, feature) => {
    e.preventDefault();
    onCategoryChange(feature.category_id);
  };
  const handleFilterClick = () => {
    setShowFilter(true);
  };
  const handleBack = () => {
    setShowFilter(false);
  };
  const handleOptionChange = (filterType, key, value) => {
    const updatedFilters = { ...selectionFilters };
    if (value) {
      updatedFilters[filterType] = [...updatedFilters[filterType], key];
    } else {
      updatedFilters[filterType] = updatedFilters[filterType].filter(
        (cur) => cur !== key
      );
    }
    setSelectionFilters(updatedFilters);
  };
  const handleApplyFilter = () => {
    setActiveFilters({ ...selectionFilters });
    setShowFilter(false);
  };
  const handleClear = () => {
    setSelectionFilters({ ...initialFilters });
    setActiveFilters({ ...initialFilters });
    setShowFilter(false);
  };

  const getCategorySection = (feature, idx) => {
    return (
      <div key={idx} className={styles.categorySection}>
        <Heading
          title={feature.category_name}
          linkText={`View All (${feature.places.length})`}
          onLinkClick={(e) => handleViewAllClick(e, feature)}
        >
          {selectedCategoryId && (
            <div
              className={cx({
                [styles.filterButton]: true,
                [styles.active]: isFilterActive,
              })}
              onClick={handleFilterClick}
            >
              <i className="las la-filter" />
            </div>
          )}
        </Heading>
        <div
          className={cx({
            "scroll-list": true,
            "scroll-list-grid": !!selectedCategoryId,
          })}
        >
          {feature.places.map((place) => (
            <PlaceCard key={place.id} place={place} />
          ))}
        </div>
      </div>
    );
  };

  let shownFeatures = [...features];

  if (!selectedCategoryId) {
    shownFeatures = features.filter((feature) => feature.places?.length);
  } else {
    shownFeatures = shownFeatures.filter(
      (feature) => feature.category_id === selectedCategoryId
    );

    if (shownFeatures[0] && isFilterActive) {
      const filteredPlaces = shownFeatures[0].places?.filter((place) => {
        let included = true;

        Object.keys(activeFilters).forEach((filterType) => {
          if (activeFilters[filterType].length) {
            switch (filterType) {
              case "prices":
                if (
                  !activeFilters[filterType].includes(
                    place.price_range.toString()
                  )
                ) {
                  included = false;
                }
                break;
              case "types":
                if (
                  !activeFilters[filterType].some((placeTypeId) =>
                    place.place_type.includes(placeTypeId)
                  )
                ) {
                  included = false;
                }
                break;
              case "amenities":
                if (
                  !activeFilters[filterType].some((amenityId) =>
                    place.amenities.includes(amenityId)
                  )
                ) {
                  included = false;
                }
                break;
              default:
                break;
            }
          }
        });
        return included;
      });
      shownFeatures[0] = {
        ...shownFeatures[0],
        places: filteredPlaces,
      };
    }
  }

  return (
    <>
      {shownFeatures.map((feature, idx) => getCategorySection(feature, idx))}
      <Dialog
        title="Filter"
        active={showFilter}
        onBackClick={handleBack}
        onClearClick={handleClear}
      >
        <div className={styles.filterContent}>
          <GroupOption
            title="Price"
            options={priceOptions}
            onChange={(key, value) => handleOptionChange("prices", key, value)}
          />
          <GroupOption
            title="Types"
            options={typesOptions}
            onChange={(key, value) => handleOptionChange("types", key, value)}
          />
          <GroupOption
            title="Amenities"
            options={amenitiesOptions}
            onChange={(key, value) =>
              handleOptionChange("amenities", key, value)
            }
          />
          <div className={styles.applyButton} onClick={handleApplyFilter}>
            Apply filter
          </div>
        </div>
      </Dialog>
    </>
  );
}

function Information({ cityDetail }) {
  const { city } = cityDetail;
  const [showInformationDialog, setShowInformationDialog] = useState(false);
  const handleReadMoreClick = (e) => {
    e.preventDefault();
    setShowInformationDialog(true);
  };
  const handleBack = (e) => {
    setShowInformationDialog(false);
  };

  return (
    <>
      <div className={styles.information}>
        <h3>City Informations</h3>
        <p>{city?.description}</p>
        <a href="/#" onClick={handleReadMoreClick}>
          Read more
        </a>
      </div>
      <Dialog
        title="City Informations"
        active={showInformationDialog}
        onBackClick={handleBack}
      >
        <div className={styles.informationDialogContent}>
          <p>{city.description}</p>
          {city?.currency && (
            <div className={styles.informationSection}>
              <h5>Currency</h5>
              <p>{city.currency}</p>
            </div>
          )}
          {city?.language && (
            <div className={styles.informationSection}>
              <h5>Language</h5>
              <p>{city.language}</p>
            </div>
          )}
          {city?.best_time_to_visit && (
            <div className={styles.informationSection}>
              <h5>Best time to visit</h5>
              <p>{city.best_time_to_visit}</p>
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
}

function CityDetailScreen(props) {
  const { id } = useParams();
  const [city, setCity] = useState(props.location.state?.city || null);
  const [cityDetail, setCityDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  useEffect(() => {
    async function fetchCityDetail() {
      const result = await axios(requests.fetchCityDetail.replace("{id}", id));
      const cityDetail = result.data.data;
      setCity(cityDetail.city);
      setCityDetail(cityDetail);
      setLoading(false);
    }
    fetchCityDetail();
  }, [id]);

  const handleCategoryChange = (categoryId) => {
    setSelectedCategoryId(categoryId);
  };

  return (
    <div>
      <TopNav title={city?.name || ""} />
      <Header city={city} />
      <FeaturesTab
        features={cityDetail?.features}
        loading={loading}
        selectedCategoryId={selectedCategoryId}
        onCategoryChange={handleCategoryChange}
      />
      {!loading && (
        <>
          <CityMapSection
            city={city}
            cityDetail={cityDetail}
            selectedCategoryId={selectedCategoryId}
          />
          <MultiCategories
            cityDetail={cityDetail}
            selectedCategoryId={selectedCategoryId}
            onCategoryChange={handleCategoryChange}
          />
          <Information cityDetail={cityDetail} />
        </>
      )}
    </div>
  );
}

export default CityDetailScreen;
