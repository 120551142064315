import React from "react";
import { Link } from "react-router-dom";
import styles from "./Heading.module.css";

function Heading({ title, to, target, linkText, onLinkClick, children }) {
  const getLink = () => {
    if (target && to) {
      return (
        <a href={to} target={target}>
          {linkText}
        </a>
      );
    } else if (to) {
      return <Link to={to}>{linkText}</Link>;
    } else if (onLinkClick) {
      return (
        <a href="/#" onClick={onLinkClick}>
          {linkText}
        </a>
      );
    }
  };

  return (
    <div className={styles.root}>
      <h3>{title}</h3>
      {children || getLink()}
    </div>
  );
}

Heading.defaultProps = {
  linkText: "View More",
};

export default Heading;
