import React, { useState, useCallback } from "react";
import { debounce } from "lodash";
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from "react-router-dom";
import styles from "./SearchScreen.module.css";
import { Search } from "../../components";
import axios from "../../axios";
import { requests } from "../../api";

function SearchScreen() {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const searchPlaces = async (term) => {
    if (term) {
      setLoading(true);
      const result = await axios(requests.searchPlaces.replace("{term}", term));
      const places = result.data?.data?.places || [];
      setResults(places);
      setLoading(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce((val) => searchPlaces(val), 500),
    []
  );

  const handleOnChange = useCallback((e) => {
    debounceSearch(e.target.value);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.root}>
      <Search cancelVisible={true} autoFocus={true} onChange={handleOnChange} />
      {loading ? (
        <div className={styles.loader}>
          <ClipLoader color={"#00c5fd"} size={30} />
        </div>
      ) : (
        <div className={styles.results}>
          {results.map((place) => (
            <div key={place.id} className={styles.resultItem}>
              <Link to={{ pathname: `/places/${place.id}`, state: { place } }}>
                {place.name}
              </Link>
              <Link
                to={{
                  pathname: `/cities/${place.city_id}`,
                  state: { city: place.city },
                }}
              >
                {place.city?.name}
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default SearchScreen;
