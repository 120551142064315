import React from "react";
import styles from "./StoryCard.module.css";
import ClipLoader from "react-spinners/ClipLoader";
import { imageURL } from "../../api";

function StoryCard({ story, loading, onClick }) {
  const buildCategoryTexts = () => {
    return story?.categories?.map((ct, idx) => (
      <p key={idx} className={styles.categoryName}>
        {ct.name}
      </p>
    ));
  };

  return (
    <div className={styles.root} onClick={onClick}>
      {loading ? (
        <div className={styles.loader}>
          <ClipLoader color={"#00c5fd"} size={30} />
        </div>
      ) : (
        <>
          <div
            className={styles.background}
            style={{
              backgroundImage: `url(${imageURL}${story.thumb})`,
            }}
          ></div>
          <div className={styles.categories}>{buildCategoryTexts()}</div>
          {story?.title && <div className={styles.title}>{story.title}</div>}
        </>
      )}
    </div>
  );
}

export default StoryCard;
