import React from "react";
import GoogleMapReact from "google-map-react";
import styles from "./Map.module.css";
const mapStyles = require("./MapStyle.json");

function Map({
  defaultCenter,
  zoom,
  children,
  className,
  gestureHandling,
  zoomControl,
}) {
  return (
    <div className={className || styles.map}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API }}
        defaultCenter={defaultCenter}
        defaultZoom={zoom}
        options={{
          disableDefaultUI: true, // disable default map UI
          draggable: true, // make map draggable
          scaleControl: true, // allow scale controle
          gestureHandling,
          zoomControl,
          styles: mapStyles,
        }}
      >
        {children}
      </GoogleMapReact>
    </div>
  );
}

Map.defaultProps = {
  className: null,
  gestureHandling: "auto",
  zoom: 12,
  zoomControl: true,
};

export default Map;
