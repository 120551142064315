import React from "react";
import { Map, MapMarker } from "../index";
import styles from "./PlaceMap.module.css";

function PlaceMap({ place, className, gestureHandling }) {
  const { lat, lng, name, category } = place;
  const defaultCenter = { lat, lng };
  const categoryId = category?.[0] ? parseInt(category?.[0]) : null;

  const getPlaceMarker = () => {
    return <MapMarker lat={lat} lng={lng} alt={name} categoryId={categoryId} />;
  };

  return (
    <Map
      className={className || styles.root}
      defaultCenter={defaultCenter}
      zoom={14}
      gestureHandling={gestureHandling}
    >
      {getPlaceMarker()}
    </Map>
  );
}

PlaceMap.defaultProps = {
  gestureHandling: "auto",
};

export default PlaceMap;
