const baseURL = "https://insight.loca.la/api/app";
const imageURL = "https://insight.loca.la/uploads/";
// const baseURL =
//   "https://loca-proxy.herokuapp.com/https://lara-test.getgolo.com/api/app";
// const imageURL = "https://lara-test.getgolo.com/uploads/";
const requests = {
  fetchCities: "/cities",
  fetchCityDetail: "/cities/{id}",
  fetchStories: "/posts/inspiration",
  fetchPlaceDetail: "/places/{id}",
  searchPlaces: "/places/search?keyword={term}",
};

export { baseURL, imageURL, requests };
